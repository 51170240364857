import DataObject from 'o365.modules.DataObject.ts';
import { createDataObject, getDataObjectById,  dataObjectStores} from 'o365.vue.ts';
import {ref} from 'vue';
import API from 'o365.modules.data.api.ts';

const stores = {};

export default class ReportDataObject extends DataObject {
    reportData:any;
    maxRecords:number = 1000;
    latestBlobUrl:string;
    previewLoading:boolean = false;
    private _loading:boolean = false;


   
    get reportTitle(){
        return this.reportData.Title;
    }

    get loading(){
        return this._loading;
    }

    get reportID(){
        return this.reportData.ReportID;
    }

    
    constructor(options, pReportData) {
       
        super(options);
        this.reportData = pReportData;
    }
  //  load = (pParams)=>{
      //  return super.load(pParams);
 //   }

    setInitialFilter(){
        if(this.reportData.InitialFilter){
            this.filterObject.applyInitFilter(this.reportData.InitialFilter,false);
        }
    }

    async openReport(pType:string,pExtension:string){
        if(pType === "view"){
            this.previewLoading = true;
        }else{
            this._loading = true;
        }
        

        return API.requestFile(`/file/${pType}/reporting/${this.id}/${this.reportID}.${pExtension}`,{
            maxRecords:this.maxRecords,
            filter:this.filterObject.filterString
        }).then(blob=>{
            if(!blob){
                alert("Something not right");
                this._loading = false;
                this.previewLoading = false;
                return null;
            }
            const url = window.URL.createObjectURL(blob);
            if(pType !== 'view'){
                var a = document.createElement("a");
                a.href = url;
                a.download = `${this.reportID}.${pExtension}`;
                a.click();
            }else{
                this.latestBlobUrl = url;
            }
            this._loading = false;
            this.previewLoading = false;
            return url;
           /* window.open(url, '_blank');
            URL.revokeObjectURL(url);*/
           
             
        }).catch(ex=>{
            alert(ex);
        });


    }

}

const initReport = async function (report) {
   

    if (report && report["ReportID"] && report["ViewName"]) {
        let fields = JSON.parse(report["JsonDataColumns"])?.map(function (item) { return { name: item.trim(), fieldName: item.trim() } });
    
        if(report["FilterFields"]){
            report["FilterFields"] = report["FilterFields"]?.split(",").map(function (item) { return { name: item.trim(), fieldName: item.trim() } })
            report["FilterFields"].forEach(field=>{
                if(!fields.find(x=>x.name==field.name)){
                    fields.push(field);
                }
            })
        }else{
            fields = fields || JSON.parse(report["JsonDataColumns"])?.map(function (item) { return { name: item.trim(), fieldName: item.trim() } });
        }
        
       
        
        let reportSetupObject = {
            id: report["ReportID"],
            viewName: report["ViewName"],
            maxRecords: -1,
            dynamicLoading: true,
            
            fields: fields || []

        };

        let viewDef = await initViewDefinition(reportSetupObject);
        reportSetupObject['viewDefinition'] = viewDef;
       // reference =  getOrCreateDataObject(reportSetupObject);
       if (!dataObjectStores.has('site')) { dataObjectStores.set('site', new Map()); }
       dataObjectStores.get('site')!.set(reportSetupObject.id,ref(new ReportDataObject(reportSetupObject,report)));
        if(report["FilterFields"]){
            dataObjectStores.get('site')!.get(reportSetupObject.id).value.filterObject.setFieldFilters(report["FilterFields"]);
        }
        //setFieldFilters
      
       document.title =  dataObjectStores.get('site')!.get(reportSetupObject.id).value.reportTite;
      

        
        return dataObjectStores.get('site')!.get(reportSetupObject.id).value;
    }else if(report && typeof report === "string"){
        const vReportData = await getReportData(report);
        return await initReport(vReportData[0]);
    }
}

const getReportData = async function(pReport){
    const requestUrl = '/api/data/';
    const requestData = {
        "operation": "retrieve",
        "viewName": 'sviw_Reporting_ReportsLatestVersions2',                         
        "whereClause": "ReportID = '"+pReport+"'",
        "maxRecords":1,
        "fields": [
            {"name": "ViewName"},
            {"name": "ReportID"},
            {"name": "Title"},
            {"name": "ID","type":"number"},
            {"name": "JsonDataColumns"},
            {"name": "WhereCLause"},
            {"name": "InitialFilter"},
            {"name": "FilterFields"}
        ]
    };
    return await API.requestPost(requestUrl, requestData);
}

const reportStore = {
    getReport: async function(report) {
        
        let reportID = report["ReportID"];
        if (!stores[reportID]) {
            stores[reportID] = await initReport(report);
        }
        return stores[reportID];
    },
    getReportByID: async function(reportId) {
        if(!stores.hasOwnProperty(reportId)){
            stores[reportId] = await initReport(reportId);
        }
        return stores[reportId];
    }
}

const initViewDefinition = async function (setupObject) {
    if (!setupObject?.viewName){
        return null;
    }

    const requestUrl = '/api/data/';
    const requestData = {
        "operation": "retrieve",
        "viewName": 'sviw_Database_ColumnsDetails',                         
        "whereClause": "DBObjectID = '"+setupObject.viewName+"'",
        "fields": [
            {"name": "FieldName"},
            {"name": "DataType"},
            {"name": "Computed"},
            {"name": "MaxLength"},
            {"name": "Identity"},
            {"name": "HasDefault"},
            {"name": "Nullable"}
        ]
    };

    try {
        const response = await API.requestPost(requestUrl, requestData);

        let matchedFields = [];

        let newKeys = {
            FieldName:'fieldName', 
            HasDefault:'hasDefault',
            Identity:'identity',
            MaxLength:'maxLength',
            Nullable:'nullable',
            Computed:'computed',
            DataType:'dataType', 
        };

        setupObject.fields.forEach(function (item) {            
            let matchedField = response.find(x => x.FieldName == item.name.trim());
            
            matchedField = renameKeys(matchedField, newKeys);
            
            matchedFields.push(matchedField);
        });

        let viewDefinition = [];

        matchedFields.forEach((field, index) =>{
            Object.keys(field).forEach((fieldProperty)=>{
                viewDefinition[index] = viewDefinition[index] || {};
                viewDefinition[index][fieldProperty] = field[fieldProperty];
            });
        });

        return viewDefinition;
    } catch (error) {
        console.log(error);
    }
}

function renameKeys(obj, newKeys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;

        return { [newKey]: obj[key] };
    });

    return Object.assign({}, ...keyValues);
}

export { initReport, reportStore }
